// @flow
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { MdAdd } from 'react-icons/lib/md';
import range from 'lodash/range';
import { Field, Form, reduxForm, change, formValueSelector } from 'redux-form';

import {
  RenderInput,
  RenderSelect,
  RenderSwitch,
  RenderTypeahead
} from '../FieldRenderer';
import {
  GRADES,
  ETHNICITIES,
  CIVIL_STATES,
  WOMAN_STATES,
  SMALLEST_KID_AGE,
  PARTICIPANT_AGES,
  YEARS_LIVING_IN_COMMUNITY,
  HOUSE_TYPES,
  HOUSE_MATES_TYPES,
} from '../../api/mock';
import { generateChoices } from '../../utils';
import { triggerSubmit } from './submit';
import validate from './validate';

import * as selectors from '../../reducers';
import * as locationsActions from '../../actions/locations';
import * as sublocationsActions from '../../actions/sublocations';
import styles from './addParticipantForm.css';


type OPTION_TYPE = {
  label: string,
  value: string,
};

type OPTIONS_TYPES = Array<OPTIONS_TYPE>;

type AddPariticipantFormPropTypes = {
  locations: OPTIONS_TYPES,
  sublocations: OPTIONS_TYPES,
  organizations: OPTIONS_TYPES,
  aliveKids: OPTIONS_TYPES,
  onSelectLocation: Function,
  onSelectSublocation: Function,
  isParticipantSelected: boolean,
  usersError: any,
};


const AddPariticipantForm = ({
  locations,
  aliveKids,
  onSelectLocation,
  onSelectSublocation,
  sublocations,
  organizations,
  handleSubmit,
  isParticipantSelected,
  usersError,
}: AddPariticipantFormPropTypes) => (
  <Fragment>
    <div className={styles.separator}>
      <span>{isParticipantSelected ? 'información' : 'o creala acá'}</span>
    </div>

    <Form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.section}>
        <div className={styles.row}>
          <div className={styles.col}>
            <Field
              name='first_name'
              label="Nombre:"
              component={RenderInput}
              placeholder="Escriba el nombre ..."
              disabled={isParticipantSelected}
            />
          </div>
          <div className={styles.col}>
            <Field
              name='last_name'
              label="Apellido:"
              component={RenderInput}
              placeholder="Escriba el appellido acá.."
              disabled={isParticipantSelected}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <Field
              name="location"
              label="Departamento"
              component={RenderSelect}
              options={locations}
              onChange={({ id }) => onSelectLocation(id)}
              isDisabled={isParticipantSelected}
            />
          </div>
          <div className={styles.col}>
            <Field
              name="sublocation"
              label="Municipio"
              component={RenderSelect}
              options={sublocations}
              onChange={({ id }) => onSelectSublocation(id)}
              isDisabled={isParticipantSelected}
            />
          </div>
          <div className={styles.col}>
            <Field
              name="community"
              label="Comunidad"
              component={RenderSelect}
              options={organizations}
              isDisabled={isParticipantSelected}
            />
          </div>
          <div className={styles.col}>
            <Field
              name="group"
              label="País"
              component={RenderInput}
              disabled={isParticipantSelected}
            />
          </div>
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.row}>
          <div className={styles.col}>
            <Field
              name='cui'
              label="Código unico de identificación"
              component={RenderInput}
              placeholder="Escriba acá..."
              disabled={isParticipantSelected}
            />
          </div>
          <div className={styles.col}>
            <Field
              name="age"
              label="¿Cuántos años cumplidos tiene?"
              component={RenderSelect}
              options={PARTICIPANT_AGES}
              isDisabled={isParticipantSelected}
            />
          </div>
          {/* <div className={styles.col}>
            <Field
              name="years_living_in_community"
              label="¿Hace cuánto vive en esta comunidad?"
              component={RenderSelect}
              options={YEARS_LIVING_IN_COMMUNITY}
              isDisabled={isParticipantSelected}
            />
          </div> */}
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <Field
              name="civil_state"
              label="¿Cuál es su estado civil?"
              component={RenderSelect}
              options={CIVIL_STATES}
              isDisabled={isParticipantSelected}
            />
          </div>
          <div className={styles.col}>
            <Field
              name="ethnicity"
              label="¿A qué grupo cultural pertenece?"
              component={RenderSelect}
              options={ETHNICITIES}
              isDisabled={isParticipantSelected}
            />
          </div>
          {/* <div className={styles.col}>
            <Field
              name="woman_state"
              label="¿Cuál es el estado fisiológico de la entrevistada?"
              component={RenderSelect}
              options={WOMAN_STATES}
              isDisabled={isParticipantSelected}
            />
          </div> */}
        </div>
        {/* <div className={styles.row}>
          <div className={styles.col}>
            <Field
              name='house_type'
              label="¿La casa dónde vive es?"
              component={RenderInput}
              placeholder="Escriba acá..."
              disabled={isParticipantSelected}
            />
          </div>
          <div className={styles.col}>
            <Field
              name="housemates"
              label="¿Cuántas personas viven en su hogar?"
              component={RenderInput}
              placeholder="Escriba acá..."
              isDisabled={isParticipantSelected}
            />
          </div>
          <div className={styles.col}>
            <Field
              name='house_bedrooms'
              label="¿Cuántos cuartos para dormir tiene su hogar?"
              component={RenderInput}
              placeholder="Escriba acá..."
              disabled={isParticipantSelected}
            />
          </div>
        </div> */}
        {/* <div className={styles.row}>
          <div className={styles.col}>
            <Field
              name="has_electric_service"
              label="¿Tiene servicio electricidad en su casa?"
              component={RenderSwitch}
              isDisabled={isParticipantSelected}
            />
          </div>
          <div className={styles.col}>
            <Field
              name="can_read"
              label="¿Sabe leer?"
              component={RenderSwitch}
              isDisabled={isParticipantSelected}
            />
          </div>
        </div> */}
        {/* <div className={styles.row}>
          <div className={styles.col}>
            <Field
              name="grades"
              label="¿Hasta qué grado llego a la escuela? Si la madre menciona un grado que no aparece, busque el grado anterior."
              component={RenderSelect}
              options={GRADES}
              isDisabled={isParticipantSelected}
            />
          </div>
          <div className={styles.col}>
            <Field
              name="alive_kids"
              label="¿Cuántos hijos vivos tiene?"
              component={RenderSelect}
              options={aliveKids}
              isDisabled={isParticipantSelected}
            />
          </div>
          <div className={styles.col}>
            <Field
              name="smallest_kid_age"
              label="Edad del hijo más pequeño"
              component={RenderSelect}
              options={SMALLEST_KID_AGE}
              isDisabled={isParticipantSelected}
            />
          </div>
        </div> */}
      </div>
      {/* <div className={styles.section}>
        <div className={styles.row}>
          <div className={styles.col}>
            <Field
              name="is_husband_present"
              label="¿El compañero/esposo de la mujer entrevistada está presente al momento de la entrevista?"
              component={RenderSwitch}
              isDisabled={isParticipantSelected}
            />
          </div>
          <div className={styles.col}>
            <Field
              name="is_husband_acknowledged"
              label="Si está presente, manifiesta interés y esta al tanto de lo aprendido por su compañera/esposa?"
              component={RenderSwitch}
              isDisabled={isParticipantSelected}
            />
          </div>
        </div>
      </div> */}
      {
        usersError && usersError.data 
        && (
          <div className={styles.errorMessage}>
            { usersError.data.message }
          </div>
        )
      }
    </Form>
  </Fragment>
);


const formParticipant = connect(
  state => {
    const formSelector = formValueSelector('addParticipantForm');
    const formInSubmissionsSelectors = formValueSelector('addInstrumentSubmission');
    const selectedStudy = selectors.getSelectedStudy(state) || {};
    const studyOrganizations = selectedStudy.organizations || [];
    const usersError = selectors.getUsersError(state);

    const locations = studyOrganizations.locations || [];

    const location = formSelector(state, 'location');
    const sublocation = formSelector(state, 'sublocation');
    
    let sublocations = [];
    let organizations = [];

    if (location != null) {
      sublocations = (studyOrganizations.sublocations || []).filter(el => el.location == location);
    }

    if (sublocation != null) {
      organizations = (studyOrganizations.organizations || []).filter(el => el.sublocation_id == sublocation);
    }

    // Null options
    sublocations.push({ id: -1, name: 'Select...' });
    organizations.push({ id: -1, name: 'Select...' });

    return ({
      usersError,
      locations: generateChoices(locations, el => el.name),
      sublocations: generateChoices(sublocations, el => el.name),
      organizations: generateChoices(organizations, el => el.name, el => el.organization),
      aliveKids: Array.from({ length: 11 }, (x,i) => ({ value: i, label: i })),
      isParticipantSelected: formInSubmissionsSelectors(state, 'participant') != null,
    })
  },
  dispatch => ({
    onSelectLocation() {
      dispatch(change('addParticipantForm', 'sublocation', -1));
      dispatch(change('addParticipantForm', 'community', -1));
    },
    onSelectSublocation() {
      dispatch(change('addParticipantForm', 'community', -1));
    },
  })
)(AddPariticipantForm);

export default reduxForm({
  form: 'addParticipantForm',
  onSubmit: triggerSubmit,
  validate,
})(formParticipant);
